// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-offerings-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\offerings.js" /* webpackChunkName: "component---src-pages-offerings-js" */),
  "component---src-pages-partnering-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\partnering.js" /* webpackChunkName: "component---src-pages-partnering-js" */),
  "component---src-pages-terms-and-condition-js": () => import("C:\\webroot\\sidcweb\\agriculture-sidc-coop\\src\\pages\\terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\webroot\\sidcweb\\agriculture-sidc-coop\\.cache\\data.json")

